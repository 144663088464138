<template>
  <div class="breadcrumb-bg">
    <v-container class="py-0">
      <v-breadcrumbs class="hidden-sm-and-down" :items="items"></v-breadcrumbs>
    </v-container>
  </div>
</template>
<style lang="scss">
.breadcrumb-bg {
  background-color: var(--v-grey-lighten3);
}
.v-breadcrumbs {
  padding: 12px 0 !important;
  // li {
  //   font-size: 12px;
  // }
}
.v-breadcrumbs__item {
  color: $text-color !important;
}
.v-breadcrumbs__item--disabled {
  color: $text-color !important;
  font-weight: bold;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .v-breadcrumbs {
    padding: 10px 0 0px 0;
  }
}
</style>
<script>
export default {
  name: "Breadcrumb",
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  jsonld() {
    const items = this.items.map((item, index) => {
      return {
        "@type": "ListItem",
        position: index + 1,
        item: {
          "@id":
            typeof item.to === "object"
              ? `https://www.tigros.it/${item.to?.params?.pathMatch || ""}`
              : item.to,
          name: item.text
        }
      };
    });
    return {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: items
    };
  }
};
</script>
